import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { addEmployee, updEmployee, delEmployee } from "./EmployeeSlice";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppFormHeader } from "components/IppFormHeader";
import { IppAutocomplete } from "components/IppAutocomplete";
import { IppTextField } from "components/IppTextField";
import { fetchCompanies } from "features/benefits/companies/CompaniesSlice";
import { IppFormDivider } from "components/IppFormDivider";
import { fetchGenders } from "features/datalists/GenderSlice";
import { fetchIndigenousGroupsByClient } from "features/datalists/IndigenousGroupSlice";
import LoadingIndicator from "components/LoadingIndicator";
import { fetchDeleteChecksByEmployee } from "features/deleteCheck/DeleteCheckSlice";
import { IppEditPencil } from "components/IppEditPencil";
import { fetchJobPositionsByClient } from "features/datalists/JobPositionSlice";
import { Prompt } from "react-router";
import ApprenticeshipStatusTypes from "components/ApprenticeshipStatus";
import { fetchWorkHourDetailsByEmployee } from "../workHours/WorkHourDetailSlice";
import { ConvertDateOffset, GetJSDate } from "utils/DateFunctions";
import { IppDisplayField } from "components/IppDisplayField";
import { IsExtraLargeScreen, IsMedSmall } from "utils/mediaQueries";
import { IppDiversityCheckboxes } from "components/IppDiversityCheckboxes";
import { IppDiversityDisplay } from "components/IppDiversityDisplay";
import { UserWriteAccess } from "utils/userAccess";
import { IppLocationAutoComplete } from "components/IppLocationAutoComplete";
import { IppTabPanel } from "components/IppTabPanel";
import { IppChildInventory } from "components/Inventory/IppChildInventory";
import { fetchJobHistoryByEmployee } from "../jobHistory/JobHistorySlice";
import { IppDatePicker } from "components/IppDatePicker";
import { IppCheckbox } from "components/IppCheckbox";
import { useTypedTranslation } from "utils/customHooks";
import { EmployeeValidationSchema } from "./EmployeeValidation";
import { IppDisplayCheckbox } from "components/IppDisplayCheckbox";
import {
  PositionData,
  createNewPositionValuesObject,
  handlePositionChange,
} from "utils/benefits/jobPositionChangeFunctions";
import { DiversityProfileFormValues, Employee } from "api/employeeAPI";
import { ExtendedJobHistory } from "api/benefits/jobHistoryAPI";
import { fetchRegionsByRecordType } from "../regions/RegionSlice";
import { IppNeedsReviewBanner } from "components/IppNeedsReviewBanner";
import IppConfirmDialog from "components/IppConfirmDialog";
import { fetchCustomJobPositionsByClient } from "features/datalists/CustomJobPositionSlice";
import { fetchEthnicitys } from "features/platform/admin/ethnicity/EthnicitySlice";
import { IppDisplayChip } from "components/IppDisplayChip";
import { IppTokenMultiSelect } from "components/IppTokenMultiSelect";
import { Company } from "api/companyAPI";
import { CompanyOptionsRender } from "utils/renderFunctions";
import { arraysAreEqual } from "utils/functions";
import { WarningAmber } from "@mui/icons-material";
import { fetchCustomEmpCategories } from "features/platform/admin/customEmpCategory/CustomEmpCategorySlice";
import { IppLocationDisplay } from "components/IppLocationDisplay";

const PREFIX = "EmployeeForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    // minWidth: 650,
    maxWidth: 1150,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const EmployeeForm = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);
  const customBP = IsMedSmall();

  const [activeTab, setActiveTab] = useState(0);

  const [pageLoading, setPageLoading] = useState(true);
  const [showApprentice, setShowApprentice] = useState(false);
  const [hasPosition, setHasPosition] = useState(false);
  const [isNewPosition, setIsNewPosition] = useState(false);
  const [genderKey, setGenderKey] = useState(0);
  const [indigenousKey, setIndigenousKey] = useState(0);
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);

  let employeeData = props.employee || {};

  const [isEditing, setIsEditing] = useState(
    employeeData.EmployeeID ? false : true
  );
  const [isAdding, setIsAdding] = useState(
    employeeData.EmployeeID ? false : true
  );
  const showEdit = UserWriteAccess("Benefits", employeeData.ProjectID);

  const {
    clientId,
    ft_ben_TempEmployee,
    ft_ben_DiversityDeclined,
    ft_ben_JobPosition,
    ft_ben_JobHistory,
    ft_ben_Region,
    ft_ben_CustomJobPosition,
    ft_ben_AltWorkHours2,
    ft_ben_AltWorkHours3,
    ft_ben_Ethnicity,
    ft_ben_CustomEmpCategory,
    ft_ben_EmployeePostalCode,
    isLoading: clientIsLoading,
  } = useSelector((state: RootState) => state.client);

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const { employeeList, isLoading, employeesById } = useSelector(
    (state: RootState) => state.employees
  );

  const employees = employeeList.map((id) => employeesById[id]);

  const {
    ethnicityList,
    ethnicitysById,
    isLoading: ethnicityIsLoading,
  } = useSelector((state: RootState) => state.ethnicity);
  const ethnicities = ft_ben_Ethnicity
    ? ethnicityList.map((id) => ethnicitysById[id])
    : [];

  const employeeEthnicity = employeeData?.EmployeeEthnicity ?? [];

  const nameEthnicityString = employeeEthnicity[0]?.EthnicityName ?? "";
  /* .map(
      (ethnicity: { EthnicityID: number; EthnicityName: string }) =>
        ethnicity.EthnicityName ?? ""
    )
    .join(","); */

  const {
    companiesById,
    companyList,
    isLoading: companyIsLoading,
  } = useSelector((state: RootState) => state.companies);

  const companies = companyList.map((CompanyID) => companiesById[CompanyID]);
  const confirmedCompanies = companies.filter((comp) => comp.ListingConfirmed);

  const {
    gendersById,
    genderList,
    isLoading: genderIsLoading,
  } = useSelector((state: RootState) => state.genders);

  const genders = genderList.map((GenderID) => gendersById[GenderID]);

  const { regionList, regionsById, regionIsLoading } = useSelector(
    (state: RootState) => state.region
  );

  const regions = ft_ben_Region ? regionList.map((id) => regionsById[id]) : [];
  const hasRegionTypes = !!(ft_ben_Region && regions.length > 0);

  const {
    indigenousGroupList,
    indigenousGroupsById,
    isLoading: indigenousGroupIsLoading,
  } = useSelector((state: RootState) => state.indigenousGroups);

  const indigenousGroups = indigenousGroupList.map(
    (IndigenousGroupID) => indigenousGroupsById[IndigenousGroupID]
  );
  const {
    customEmpCategoryList,
    customEmpCategoriesById,
    isLoading: customEmpCategoryIsLoading,
  } = useSelector((state: RootState) => state.customEmpCategories);

  const customEmpCategories = customEmpCategoryList.map(
    (id) => customEmpCategoriesById[id]
  );

  const {
    jobPositionList,
    jobPositionsById,
    isLoading: jobPositionIsLoading,
  } = useSelector((state: RootState) => state.jobPositions);

  const jobPositions = ft_ben_JobPosition
    ? jobPositionList.map((id) => jobPositionsById[id])
    : [];

  // custom job positions
  const {
    customJobPositionList,
    customJobPositionsById,
    isLoading: customJobPositionIsLoading,
  } = useSelector((state: RootState) => state.customJobPositions);
  const customJobPositions = ft_ben_CustomJobPosition
    ? customJobPositionList.map((id) => customJobPositionsById[id])
    : [];

  const {
    workHourDetailList,
    workHourDetailsById,
    isLoading: workHourIsLoading,
  } = useSelector((state: RootState) => state.workHourDetails);

  const workHourDetails = workHourDetailList.map(
    (id) => workHourDetailsById[id]
  );

  const workHourDetailsList = workHourDetails.filter(
    (item: any) => item.EmployeeID === employeeData.EmployeeID
  );

  const data = workHourDetails.map((p) => {
    let n = Object.assign({}, p) as any;
    if (p.EndDate !== null) {
      let thisDate = p.EndDate as Date;
      n.EndDate = ConvertDateOffset(thisDate);
    } else {
      n.EndDate = null;
    }

    return n;
  });

  const {
    jobHistoryList,
    jobHistoryById,
    isLoading: jobHistoryIsLoading,
  } = useSelector((state: RootState) => state.jobHistory);

  const jobHistory = jobHistoryList.map((id) => jobHistoryById[id]);

  const empJobHistory = ft_ben_JobHistory
    ? jobHistoryList
        .map((id) => jobHistoryById[id])
        .filter((item) => item.EmployeeID === employeeData.EmployeeID)
        .map((p) => {
          let n: ExtendedJobHistory = { ...p };
          if (p.EndDate) {
            n.EndDate = ConvertDateOffset(p.EndDate);
          }
          if (p.StartDate) {
            n.StartDate = ConvertDateOffset(p.StartDate);
          }
          n.PositionStatus = p.ApprenticeStatus
            ? p.ApprenticeStatus === "Apprentice"
              ? `${p.ApprenticeStatus}${
                  p.ApprenticeYear ? ` (Year ${p.ApprenticeYear})` : ""
                }`
              : p.ApprenticeStatus
            : "";
          return n;
        })
    : [];

  const altHours2 = ft_ben_AltWorkHours2
    ? [
        {
          field: "AltHours2",
          title: t("objBen:objects.workhour.detail.fields.althours1"),
          columnWidth: 80,
        },
      ]
    : [];

  const altHours3 = ft_ben_AltWorkHours3
    ? [
        {
          field: "AltHours3",
          title: t("objBen:objects.workhour.detail.fields.althours2"),
          columnWidth: 80,
        },
      ]
    : [];

  const whdColumns = [
    { field: "ProjectName", title: "Project" },
    { field: "ProjectComponentName", title: "Component", columnWidth: 150 },
    {
      field: "EndDate",
      title: "Date",
      filter: "date",
      format: "{0:d}",
      columnWidth: 110,
    },
    { field: "RegularHours", title: "Reg", columnWidth: 80 },
    ...altHours2,
    ...altHours3,
  ];

  const jhColumns = ft_ben_JobHistory
    ? [
        { field: "JobPositionName", title: "Job Position" },
        { field: "PositionStatus", title: "Position Status" },
        ...(ft_ben_TempEmployee
          ? [
              {
                field: "TempEmployee",
                title: IsExtraLargeScreen() ? "Temporary" : "Temp",
                checkbox: true,
                clearFalseValues: true,
                columnWidth: IsExtraLargeScreen() ? 100 : 70,
              },
            ]
          : []),
        {
          field: "StartDate",
          title: "Start Date",
          filter: "date",
          format: "{0:d}",
          columnWidth: 120,
        },
        {
          field: "EndDate",
          title: "End Date",
          filter: "date",
          format: "{0:d}",
          columnWidth: 120,
        },
      ]
    : [];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        dispatch(fetchIndigenousGroupsByClient(accessToken));
        dispatch(fetchCompanies(accessToken, clientId));
        dispatch(fetchGenders(accessToken));
        if (ft_ben_JobPosition)
          dispatch(fetchJobPositionsByClient(accessToken));
        if (ft_ben_CustomJobPosition)
          dispatch(fetchCustomJobPositionsByClient(accessToken));
        if (ft_ben_Region)
          dispatch(fetchRegionsByRecordType(accessToken, "Employee"));
        if (ft_ben_Ethnicity) {
          dispatch(fetchEthnicitys(accessToken));
        }
        if (ft_ben_CustomEmpCategory) {
          dispatch(fetchCustomEmpCategories(accessToken));
        }

        if (employeeData.EmployeeID) {
          dispatch(
            fetchWorkHourDetailsByEmployee(accessToken, employeeData.EmployeeID)
          );
          if (ft_ben_JobHistory)
            dispatch(
              fetchJobHistoryByEmployee(accessToken, employeeData.EmployeeID)
            );
        }
        setPageLoading(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  // check if active position is apprenticeable trade
  // and show apprentice dropdown when appropriate

  useEffect(() => {
    if (
      ft_ben_JobPosition &&
      jobPositions.length > 0 &&
      !hasPosition &&
      !jobPositionIsLoading
    ) {
      if (!isAdding) {
        const posID = employeeData.JobPositionID;
        if (posID !== null) {
          const pos = jobPositions.find((d) => d.JobPositionID === posID);
          setHasPosition(true); // to avoid too many re-renders
          if (pos) {
            if (pos.ApprenticeableTrade) {
              setShowApprentice(true);
            } else {
              setShowApprentice(false);
            }
          }
        }
      }
    }
  }, [ft_ben_JobPosition, jobPositions]);

  // Cleanup isNewPosition when leaving edit mode
  useEffect(() => {
    return () => {
      if (!isEditing && isNewPosition) {
        setIsNewPosition(false);
      }
    };
  }, [isEditing]);

  const resetShowApprentice = () => {
    const originalPositionId = employeeData.JobPositionID;
    if (originalPositionId) {
      const originalPosition = jobPositions.find(
        (position) => position.JobPositionID === originalPositionId
      );
      setShowApprentice(originalPosition?.ApprenticeableTrade ?? false);
    } else {
      setShowApprentice(false);
    }
  };

  useEffect(() => {
    if (ft_ben_JobPosition && !isEditing) {
      resetShowApprentice();
    }
  }, [isEditing, jobPositions, employeeData.JobPositionID]);

  const handleDelete = () => {
    // function to delete current Employee entry
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delEmployee(accessToken, employeeData.EmployeeID));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const prepareSubmitValues = (values: any) => {
    const JobPositionID = values?.JobPositionID;
    if (!JobPositionID) {
      // No position selected, return original values
      return values;
    }
    const selectedPosition = jobPositions?.find(
      (position) => position?.JobPositionID === JobPositionID
    );
    // If the job position is not apprenticeable, clear apprentice-related fields
    if (selectedPosition && !selectedPosition?.ApprenticeableTrade) {
      return {
        ...values,
        ApprenticeStatus: null,
        ApprenticeYear: null,
      };
    }
    // Return unchanged values if apprenticeable
    return values;
  };

  const handleSubmit = (values: any) => {
    const newValues = prepareSubmitValues(values);
    if (isAdding) {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(addEmployee(accessToken, newValues));
        } catch (e) {
          console.error(e);
        }
      })();
    } else {
      if (
        !newValues.IndigenousGroupID &&
        (newValues.IndigenousGroupRaw?.trim() ?? "") !== ""
      ) {
        setWarningDialogOpen(true);
      } else {
        (async () => {
          try {
            const accessToken = await getAccessTokenSilently({
              authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
              },
            });
            dispatch(updEmployee(accessToken, values.EmployeeID, newValues));
          } catch (e) {
            console.error(e);
          }
        })();
      }
    }
  };

  const handleWarningDialogContinue = () => {
    setWarningDialogOpen(false);
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(
          updEmployee(accessToken, formik.values.EmployeeID, formik.values)
        );
      } catch (e) {
        console.error(e);
      }
    })();
  };

  if (isAdding) {
    //set defaults for create case
    employeeData.CompanyEmployeeID = "";
    employeeData.CompanyID = -1;
    employeeData.ProvinceState = "";
    employeeData.CountryName = "";
    employeeData.TownCity = "";
    employeeData.GenderID = null;
    employeeData.IndigenousGroupID = null;
    employeeData.JobPositionID = null;
    employeeData.CustomJobPositionID = null;
    employeeData.ApprenticeStatus = null;
    employeeData.ApprenticeYear = null;
    employeeData.VisibleMinority = false;
    employeeData.Local = false;
    employeeData.Disability = false;
    employeeData.LGBTQ = false;
    employeeData.RegionID = null;
    employeeData.TempEmployee = false;
    employeeData.DiversityDeclined = false;
    employeeData.ValidFrom = null;
    employeeData.EmployeeEthnicity = [];
    employeeData.CustomEmpCategoryID = null;
  }

  const handleTabSelect = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  const formik = useFormik({
    initialValues: employeeData,
    validationSchema: EmployeeValidationSchema(
      employees,
      isEditing,
      showApprentice,
      hasRegionTypes,
      employeeData.CompanyEmployeeID,
      isNewPosition
    ),
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (ft_ben_JobHistory && isAdding) {
      if (!formik.values.JobPositionID && formik.values.ValidFrom) {
        formik.setFieldValue("ValidFrom", null);
        formik.setFieldTouched("ValidFrom", true);
      }
    }
  }, [
    ft_ben_JobHistory,
    isAdding,
    formik.values.JobPositionID,
    formik.values.ValidFrom,
  ]);

  const handleDiversityDeclinedChange = () => {
    const newValue = !formik.values.DiversityDeclined;

    // Define the intended values for when DiversityDeclined is true
    const newValues: Partial<DiversityProfileFormValues> = newValue
      ? {
          Disability: false,
          VisibleMinority: false,
          LGBTQ: false,
          Local: false,
          GenderID: null,
          IndigenousGroupID: null,
          EmployeeEthnicity: [],
          CustomEmpCategoryID: null,
        }
      : {};

    if (formik.values.GenderID !== newValues.GenderID)
      setGenderKey(genderKey + 1);

    if (formik.values.IndigenousGroupID !== newValues.IndigenousGroupID)
      setIndigenousKey(indigenousKey + 1);

    // Object to track fields that need to be touched
    const fieldsToTouch: Partial<
      Record<keyof DiversityProfileFormValues, boolean>
    > = {};

    // Check if DiversityDeclined has changed
    if (formik.values.DiversityDeclined !== newValue) {
      fieldsToTouch.DiversityDeclined = true;
    }

    // Iterate over newValues to check for changes
    Object.entries(newValues).forEach(([field, intendedValue]) => {
      const currentValue =
        formik.values[field as keyof DiversityProfileFormValues];

      // Iterate over newValues to check for changes
      if (
        Array.isArray(currentValue) &&
        Array.isArray(intendedValue) &&
        !arraysAreEqual(currentValue, intendedValue)
      ) {
        fieldsToTouch[field as keyof DiversityProfileFormValues] = true;
      } else if (
        !Array.isArray(currentValue) &&
        currentValue !== intendedValue
      ) {
        fieldsToTouch[field as keyof DiversityProfileFormValues] = true;
      }
    });

    // Update values and touched state
    formik.setValues({
      ...formik.values,
      DiversityDeclined: newValue,
      ...newValues,
    });

    formik.setTouched(
      fieldsToTouch as Record<keyof DiversityProfileFormValues, boolean>,
      false
    );
  };

  const diversityDeclinedButton = {
    id: "DiversityDeclined",
    label: "Declined?",
    value: formik.values.DiversityDeclined,
    onChangeFunction: handleDiversityDeclinedChange,
    error: formik.errors.DiversityDeclined,
    touched: formik.touched.DiversityDeclined,
  };

  const updatePositionState = (
    newValue: any,
    employeeData: Employee,
    fieldName: string,
    formikValues: PositionData
  ) => {
    const newPositionObject: PositionData = createNewPositionValuesObject(
      newValue,
      employeeData.JobPositionID,
      fieldName,
      formikValues
    );

    if (ft_ben_JobPosition && ft_ben_JobHistory) {
      const positionHasChanged = handlePositionChange(
        newPositionObject,
        employeeData
      );

      if (positionHasChanged !== isNewPosition) {
        setIsNewPosition(positionHasChanged);
        if (positionHasChanged)
          newPositionObject.ValidFrom = ConvertDateOffset(new Date())
            .toISOString()
            .slice(0, 10);
        if (!positionHasChanged)
          newPositionObject.ValidFrom = employeeData.ValidFrom;
      }
    }

    // Object to track fields that need to be touched
    const fieldsToTouch: Partial<Record<keyof PositionData, boolean>> = {};

    // Iterate over newValues to check for changes
    Object.entries(newPositionObject).forEach(([field, newValue]) => {
      if (formikValues[field as keyof PositionData] !== newValue) {
        fieldsToTouch[field as keyof PositionData] = true;
      }
    });

    // Update values and touched state
    formik.setValues({
      ...formikValues,
      ...newPositionObject,
    });

    formik.setTouched(
      fieldsToTouch as Record<keyof PositionData, boolean>,
      false
    );
  };

  const generateJobPositionName = () => {
    const { JobPositionName, ApprenticeStatus, ApprenticeYear } = formik.values;

    if (!showApprentice) {
      return JobPositionName;
    }

    let apprenticeInfo = ` (${ApprenticeStatus || "unknown"}`;
    if (ApprenticeStatus === "Apprentice") {
      apprenticeInfo += `, Year ${ApprenticeYear || "-"})`;
    } else {
      apprenticeInfo += ")";
    }

    return `${JobPositionName}${apprenticeInfo}`;
  };

  const generateCustomJobPositionName = () => {
    const { CustomJobPositionID } = formik.values;
    const customJobPosition = customJobPositions.find(
      (customJobPosition) =>
        customJobPosition.CustomJobPositionID === CustomJobPositionID
    );
    return customJobPosition?.JobPositionName;
  };

  const generateCustomEmpCategoryName = () => {
    const { CustomEmpCategoryID } = formik.values;
    const customEmpCategory = customEmpCategories.find(
      (customEmpCategory) =>
        customEmpCategory.CustomEmpCategoryID === CustomEmpCategoryID
    );
    return customEmpCategory?.Name;
  };

  let viewForm = (
    <Root>
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>
          <Grid container className={classes.editForm} spacing={1}>
            <IppFormHeader
              title="Employee"
              isEditing={isEditing}
              isAdding={isAdding}
              // returnPath="/benefits/employees"
            />
            <IppFormDivider title="Employee Details" />

            {currentProfile.IsClient && employeeData?.NeedsReview && (
              <IppNeedsReviewBanner
                objectTitle="Employee"
                acceptOnClick={() => {
                  setIsEditing(true);
                }}
              />
            )}

            <Grid item xs={4}>
              <IppDisplayField
                label="Employee ID"
                value={formik.values.CompanyEmployeeID}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                showEdit={showEdit}
              />
            </Grid>
            <IppLocationDisplay
              address={formik.values.Address}
              townCity={formik.values.TownCity}
              provinceState={formik.values.ProvinceState}
              country={formik.values.CountryName}
              postalCode={
                ft_ben_EmployeePostalCode ? formik.values.PostalCode : ""
              }
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              showEdit={currentProfile.IsClient && showEdit}
            />

            <IppFormDivider title="Work Details" />

            <Grid item xs={6}>
              <IppDisplayField
                label="Employer"
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                value={formik.values.CompanyName}
                showEdit={showEdit}
                isWebLink={
                  currentProfile.IsClientAdmin && formik.values.CompanyName
                    ? true
                    : false
                }
                route={`${window.location.href.substring(
                  0,
                  window.location.href.lastIndexOf("employees")
                )}companies/${formik.values.CompanyID}`}
              />
            </Grid>
            {ft_ben_JobPosition && (
              <Grid item xs={6}>
                <IppDisplayField
                  label="Job Position"
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  value={generateJobPositionName()}
                  showEdit={showEdit}
                />
              </Grid>
            )}
            {hasRegionTypes && (
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objBen:objects.employee.fields.region")}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  value={formik.values.RegionName}
                  showEdit={showEdit}
                />
              </Grid>
            )}
            {ft_ben_CustomJobPosition && (
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objBen:objects.employee.fields.customJobPosition")}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  value={generateCustomJobPositionName()}
                  showEdit={showEdit}
                />
              </Grid>
            )}
            {ft_ben_TempEmployee && (
              <Grid item xs={6} style={{ marginTop: 30 }}>
                <IppDisplayCheckbox
                  label={t("objBen:objects.employee.fields.tempemployee")}
                  isChecked={formik.values.TempEmployee}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
            )}
            <IppFormDivider
              title="Diversity Profile"
              conditionalText={
                ft_ben_DiversityDeclined && employeeData?.DiversityDeclined
                  ? "[Declined]"
                  : undefined
              }
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              showEdit={
                ft_ben_DiversityDeclined && employeeData?.DiversityDeclined
              }
            />
            <Grid item xs={6}>
              <IppDisplayField
                label="Gender"
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                value={
                  ft_ben_DiversityDeclined && employeeData?.DiversityDeclined
                    ? "Declined"
                    : !genderIsLoading &&
                      !pageLoading &&
                      formik.values.GenderID &&
                      formik.values.GenderID > 0
                    ? gendersById[formik.values.GenderID].GenderText
                    : "Unspecified"
                }
                showEdit={
                  ft_ben_DiversityDeclined && employeeData?.DiversityDeclined
                    ? false
                    : showEdit
                }
                disabled={
                  ft_ben_DiversityDeclined && employeeData?.DiversityDeclined
                }
              />
            </Grid>
            {ft_ben_Ethnicity && (
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objBen:objects.employee.fields.ethnicity", {
                    count: nameEthnicityString?.split(",").length,
                  })}
                  value={
                    ft_ben_DiversityDeclined && employeeData?.DiversityDeclined
                      ? "Declined"
                      : nameEthnicityString
                  }
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={
                    ft_ben_DiversityDeclined && employeeData?.DiversityDeclined
                      ? false
                      : showEdit
                  }
                  disabled={
                    ft_ben_DiversityDeclined && employeeData?.DiversityDeclined
                  }
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <IppDisplayField
                label={t(
                  "objBen:objects.employee.fields.indigenousgroupcountry"
                )}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                value={
                  ft_ben_DiversityDeclined && employeeData?.DiversityDeclined
                    ? "Declined"
                    : !indigenousGroupIsLoading &&
                      !pageLoading &&
                      formik.values.IndigenousGroupID &&
                      formik.values.IndigenousGroupID > 0 &&
                      indigenousGroupsById[formik.values.IndigenousGroupID]
                    ? indigenousGroupsById[formik.values.IndigenousGroupID]
                        .IndigenousGroupName
                    : "Not Applicable"
                }
                showEdit={
                  ft_ben_DiversityDeclined && employeeData?.DiversityDeclined
                    ? false
                    : showEdit
                }
                disabled={
                  ft_ben_DiversityDeclined && employeeData?.DiversityDeclined
                }
              />
            </Grid>
            {ft_ben_CustomEmpCategory && (
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objBen:objects.employee.fields.customCategory")}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  value={
                    ft_ben_DiversityDeclined && employeeData?.DiversityDeclined
                      ? "Declined"
                      : generateCustomEmpCategoryName()
                  }
                  showEdit={
                    ft_ben_DiversityDeclined && employeeData?.DiversityDeclined
                      ? false
                      : showEdit
                  }
                  disabled={
                    ft_ben_DiversityDeclined && employeeData?.DiversityDeclined
                  }
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <IppDiversityDisplay
                parentTitle="Employee"
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                disability={formik.values.Disability}
                visibleMinority={formik.values.VisibleMinority}
                lgbtq={formik.values.LGBTQ}
                local={formik.values.Local}
                showEdit={showEdit}
                checkboxesDisabled={
                  ft_ben_DiversityDeclined && formik.values.DiversityDeclined
                }
              />
            </Grid>
            <Grid item xs={12}>
              <IppFormButtons
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                isAdding={isAdding}
                showDelete={showEdit}
                deleteFunction={handleDelete}
                resetFunction={() => formik.resetForm()}
                fetchFunc={fetchDeleteChecksByEmployee}
                info={t("strGen:prompts.deletechecks.unlinkitems")}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Root>
  );

  let editForm = (
    <Root>
      {!formik.isSubmitting && (
        <Prompt
          when={formik.dirty}
          message="You have unsaved changes; are you sure you want to leave this page?"
        />
      )}

      <form noValidate onSubmit={formik.handleSubmit}>
        <Grid container className={classes.editForm} spacing={1}>
          <IppFormHeader
            title="Employee"
            isEditing={isEditing}
            isAdding={isAdding}
          />
          <IppFormDivider title="Employee Details" />
          <Grid item xs={6}>
            <IppTextField
              id="CompanyEmployeeID"
              label="Employee ID*"
              required={false}
              value={formik.values.CompanyEmployeeID}
              onChangeFunction={formik.handleChange}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              touchedExpression={formik.touched.CompanyEmployeeID}
              errorsExpression={formik.errors.CompanyEmployeeID}
              toolTip="The Employee ID is used to hours worked and other data for a specific employee. If possible, this should match the Employee ID or Number in your system."
            />
          </Grid>
          {/* Will render when ft_ben_EmployeePostalCode is truthy */}
          <IppLocationAutoComplete
            formik={formik}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            showAddress={false}
            provinceStateOptions={{ show: true, required: true }}
            countryOptions={{ show: true, required: true }}
            postalCodeOptions={{ show: ft_ben_EmployeePostalCode }}
          />

          <IppFormDivider title="Work Details" />
          <Grid item xs={6}>
            <IppAutocomplete
              id="CompanyID"
              required={false}
              options={confirmedCompanies.sort((a, b) =>
                a.ShowAsActive.localeCompare(b.ShowAsActive)
              )}
              groupBy={(option: any) => option.ShowAsActive}
              value={confirmedCompanies.find((obj) => {
                return obj.CompanyID === formik.values.CompanyID;
              })}
              onChangeFunction={(_: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("CompanyID", newValue.CompanyID);
                } else {
                  formik.setFieldValue("CompanyID", null);
                }
              }}
              label="Employer*"
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.CompanyName}
              renderOption={(props: any, option: Company) => {
                return CompanyOptionsRender(props, option);
              }}
              textValueFunction={formik.values.CompanyName}
              touchedFunction={formik.touched.CompanyID}
              errorFunction={formik.errors.CompanyID}
            />
          </Grid>
          {ft_ben_JobPosition && (
            <>
              <Grid item xs={6}>
                <IppAutocomplete
                  id="JobPositionID"
                  toolTip="These jobs align with specific NOC codes. If you do not see a position in the list, please select one that most closely relates to the required NOC code."
                  required={ft_ben_JobPosition}
                  options={jobPositions}
                  value={jobPositions.find((obj) => {
                    return obj.JobPositionID === formik.values.JobPositionID;
                  })}
                  onChangeFunction={(
                    _: React.ChangeEvent<HTMLInputElement>,
                    newValue: any
                  ) => {
                    updatePositionState(
                      newValue,
                      employeeData,
                      "JobPositionID",
                      formik.values
                    );

                    const isApprenticeable = newValue?.ApprenticeableTrade;
                    if (showApprentice !== isApprenticeable) {
                      setShowApprentice(isApprenticeable);
                    }
                  }}
                  label="Job Position"
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  touchedFunction={formik.touched.JobPositionID}
                  errorFunction={formik.errors.JobPositionID}
                  optionLabelFunction={(option: any) => option.JobPositionName}
                  textValueFunction={
                    !jobPositionIsLoading &&
                    !pageLoading &&
                    formik.values.JobPositionID > 0
                      ? jobPositionsById[formik.values.JobPositionID]
                          .JobPositionName
                      : ""
                  }
                />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {formik.values.PositionRaw &&
                    formik.values.PositionRaw !== null &&
                    formik.values.JobPositionID === null &&
                    !isAdding && (
                      <Typography variant="caption">
                        You uploaded{" "}
                        <strong>{formik.values.PositionRaw}</strong>
                      </Typography>
                    )}
                  {ft_ben_JobHistory && isNewPosition && (
                    <Typography variant="caption">
                      <b>
                        NOTE: The previous position will be added to Job History
                      </b>
                    </Typography>
                  )}
                </Box>
              </Grid>
              {showApprentice && (
                <Grid item xs={6}>
                  <IppAutocomplete
                    id="ApprenticeStatus"
                    required={showApprentice}
                    options={ApprenticeshipStatusTypes}
                    value={formik.values.ApprenticeStatus}
                    onChangeFunction={(
                      _: React.ChangeEvent<HTMLInputElement>,
                      newValue: any
                    ) =>
                      updatePositionState(
                        newValue,
                        employeeData,
                        "ApprenticeStatus",
                        formik.values
                      )
                    }
                    textValueFunction={formik.values.ApprenticeStatus}
                    label="Apprentice Status"
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    touchedFunction={formik.touched.ApprenticeStatus}
                    errorFunction={formik.errors.ApprenticeStatus}
                  />
                </Grid>
              )}
              {formik.values.ApprenticeStatus === "Apprentice" &&
                showApprentice && (
                  <Grid item xs={6}>
                    <IppTextField
                      id="ApprenticeYear"
                      label="Apprentice Year"
                      required={
                        formik.values.ApprenticeStatus === "Apprentice" &&
                        showApprentice
                      }
                      fieldType="number"
                      value={formik.values.ApprenticeYear}
                      onChangeFunction={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const value = event.target.value
                          ? parseInt(event.target.value)
                          : null;

                        updatePositionState(
                          value,
                          employeeData,
                          "ApprenticeYear",
                          formik.values
                        );
                      }}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      touchedExpression={formik.touched.ApprenticeYear}
                      errorsExpression={formik.errors.ApprenticeYear}
                    />
                  </Grid>
                )}
              {ft_ben_JobHistory && isNewPosition && (
                <Grid item xs={6}>
                  <IppDatePicker
                    id="ValidFrom"
                    label={
                      employeeData.JobPositionID && !formik.values.JobPositionID
                        ? "Position End Date"
                        : "Position Start Date"
                    }
                    required={ft_ben_JobHistory ? isNewPosition : false}
                    disabled={isAdding && !formik.values.JobPositionID}
                    value={
                      ft_ben_JobHistory
                        ? ConvertDateOffset(formik.values.ValidFrom)
                        : null
                    }
                    disableFuture={true}
                    onChangeFunction={(newValue: any) => {
                      formik.setFieldValue(
                        "ValidFrom",
                        GetJSDate(newValue),
                        true
                      );
                      formik.setFieldTouched("ValidFrom", true, false);
                    }}
                    errorsExpression={formik.errors.ValidFrom}
                    touchedExpression={formik.touched.ValidFrom}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    monthOnly={false}
                  />
                </Grid>
              )}
            </>
          )}
          {hasRegionTypes && (
            <Grid item xs={6}>
              <IppAutocomplete
                id="RegionID"
                label={t("objBen:objects.employee.fields.region")}
                required={hasRegionTypes}
                options={regions}
                value={regions.find((obj) => {
                  return obj.RegionID === formik.values.RegionID;
                })}
                onChangeFunction={(event: ChangeEvent, newValue: any) => {
                  if (newValue) {
                    formik.setFieldValue("RegionID", newValue.RegionID);
                  } else {
                    formik.setFieldValue("RegionID", null);
                  }
                }}
                touchedFunction={formik.touched.RegionID}
                errorFunction={formik.errors.RegionID}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                optionLabelFunction={(option: any) => option.RegionName}
                textValueFunction={formik.values.RegionName}
              />
            </Grid>
          )}
          {ft_ben_CustomJobPosition && (
            <Grid item xs={6}>
              <IppAutocomplete
                id="CustomJobPositionID"
                toolTip={`If you do not see an
                appropriate ${t(
                  "objBen:objects.employee.fields.customJobPosition"
                )} in the list, please contact your Administrator.`}
                required={ft_ben_CustomJobPosition}
                options={customJobPositions}
                value={customJobPositions.find((obj) => {
                  return (
                    obj.CustomJobPositionID ===
                    formik.values.CustomJobPositionID
                  );
                })}
                onChangeFunction={(
                  _: React.ChangeEvent<HTMLInputElement>,
                  newValue: any
                ) => {
                  if (newValue) {
                    formik.setFieldValue(
                      "CustomJobPositionID",
                      newValue?.CustomJobPositionID
                    );
                  } else {
                    formik.setFieldValue("CustomJobPositionID", -1);
                  }
                }}
                label={t("objBen:objects.employee.fields.customJobPosition")}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                touchedFunction={formik.touched.CustomJobPositionID}
                errorFunction={formik.errors.CustomJobPositionID}
                optionLabelFunction={(option: any) => option.JobPositionName}
                textValueFunction={
                  !customJobPositionIsLoading &&
                  !pageLoading &&
                  formik.values.CustomJobPositionID > 0
                    ? customJobPositionsById[formik.values.CustomJobPositionID]
                        .JobPositionName
                    : ""
                }
              />
              {formik.values.CustomJobPositionRaw &&
                formik.values.CustomJobPositionRaw !== null &&
                formik.values.CustomJobPositionID === null &&
                !isAdding && (
                  <Typography variant="caption">
                    You uploaded{" "}
                    <strong>{formik.values.CustomJobPositionRaw}</strong>
                  </Typography>
                )}
            </Grid>
          )}
          {ft_ben_TempEmployee && (
            <Grid item xs={6}>
              <IppCheckbox
                id="TempEmployee"
                label={t("objBen:objects.employee.fields.tempemployee")}
                value={formik.values.TempEmployee}
                onChangeFunction={(
                  _: React.ChangeEvent<HTMLInputElement>,
                  newValue: any
                ) =>
                  updatePositionState(
                    newValue,
                    employeeData,
                    "TempEmployee",
                    formik.values
                  )
                }
                isEditing={isEditing}
              />
            </Grid>
          )}
          <IppFormDivider
            title="Diversity Profile"
            button={
              ft_ben_DiversityDeclined ? diversityDeclinedButton : undefined
            }
            isEditing={isEditing}
          />
          <Grid item xs={6}>
            <IppAutocomplete
              id="GenderID"
              key={genderKey}
              required={
                !ft_ben_DiversityDeclined || !formik.values.DiversityDeclined
              }
              disabled={
                ft_ben_DiversityDeclined && formik.values.DiversityDeclined
              }
              options={genders}
              value={genders.find((obj) => {
                return obj.GenderID === formik.values.GenderID;
              })}
              onChangeFunction={(_: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("GenderID", newValue.GenderID);
                } else {
                  formik.setFieldValue("GenderID", null);
                }
              }}
              label="Gender"
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.GenderText}
              touchedFunction={formik.touched.GenderID}
              errorFunction={formik.errors.GenderID}
              textValueFunction={
                !genderIsLoading &&
                !pageLoading &&
                formik.values.GenderID &&
                formik.values.GenderID > 0
                  ? gendersById[formik.values.GenderID].GenderText
                  : ""
              }
              placeholder={
                ft_ben_DiversityDeclined && formik.values.DiversityDeclined
                  ? "Declined"
                  : "Unspecified"
              }
            />
          </Grid>
          {ft_ben_Ethnicity && (
            <Grid item xs={6}>
              <IppAutocomplete
                id="EmployeeEthnicity"
                label={t("objBen:objects.employee.fields.ethnicity", {
                  count: 1,
                })}
                required={false}
                disabled={
                  ft_ben_DiversityDeclined && formik.values.DiversityDeclined
                }
                options={ethnicities}
                noOptionsText={t("strGen:components.nooptionstext")}
                value={
                  Array.isArray(formik.values.EmployeeEthnicity) &&
                  formik.values.EmployeeEthnicity.length > 0
                    ? formik.values.EmployeeEthnicity[0] // use the first object if array is non-empty
                    : null // otherwise, show nothing
                }
                onChangeFunction={(_: ChangeEvent, newValue: any) => {
                  if (newValue) {
                    formik.setFieldValue("EmployeeEthnicity", [newValue]);
                  } else {
                    formik.setFieldValue("EmployeeEthnicity", []);
                  }
                }}
                isEditing={true}
                setIsEditing={setIsEditing}
                optionLabelFunction={(option: any) => option.EthnicityName}
                touchedFunction={formik.touched.EmployeeEthnicity}
                errorFunction={formik.errors.EmployeeEthnicity}
                textValueFunction={
                  !ethnicityIsLoading &&
                  !pageLoading &&
                  formik.values.EmployeeEthnicity &&
                  formik.values.EmployeeEthnicity.length > 0
                    ? formik.values.EmployeeEthnicity[0].EthnicityName
                    : ""
                }
                placeholder={
                  ft_ben_DiversityDeclined && formik.values.DiversityDeclined
                    ? "Declined"
                    : ""
                }
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <IppAutocomplete
              id="IndigenousGroupID"
              key={indigenousKey}
              options={indigenousGroups}
              disabled={
                ft_ben_DiversityDeclined && formik.values.DiversityDeclined
              }
              value={indigenousGroups.find((obj) => {
                return (
                  obj.IndigenousGroupID === formik.values.IndigenousGroupID
                );
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue(
                    "IndigenousGroupID",
                    newValue.IndigenousGroupID
                  );
                } else {
                  formik.setFieldValue("IndigenousGroupID", null);
                }
              }}
              label={t("objBen:objects.employee.fields.indigenousgroupcountry")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.IndigenousGroupName}
              touchedFunction={formik.touched.IndigenousGroupID}
              errorFunction={formik.errors.IndigenousGroupID}
              textValueFunction={
                !indigenousGroupIsLoading &&
                !pageLoading &&
                formik.values.IndigenousGroupID &&
                formik.values.IndigenousGroupID > 0 &&
                indigenousGroupsById[formik.values.IndigenousGroupID]
                  ? indigenousGroupsById[formik.values.IndigenousGroupID]
                      .IndigenousGroupName
                  : ""
              }
              placeholder={
                ft_ben_DiversityDeclined && formik.values.DiversityDeclined
                  ? "Declined"
                  : ""
              }
              autoPopulate={false}
              showWarning={
                !isAdding &&
                !formik.values.IndigenousGroupID &&
                (formik.values.IndigenousGroupRaw?.trim() ?? "") !== ""
              }
            />
            {!isAdding &&
              !formik.values.IndigenousGroupID &&
              (formik.values.IndigenousGroupRaw?.trim() ?? "") !== "" &&
              (!ft_ben_DiversityDeclined ||
                !formik.values.DiversityDeclined) && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    title={t(
                      "strGen:tooltips.employee.fields.indigenousgroup.warning"
                    )}
                  >
                    <WarningAmber color="warning" fontSize="inherit" />
                  </Tooltip>
                  <Typography variant="caption" sx={{ paddingLeft: 1 }}>
                    You uploaded{" "}
                    <strong>{formik.values.IndigenousGroupRaw}</strong>
                  </Typography>
                </Box>
              )}
          </Grid>
          {ft_ben_CustomEmpCategory && (
            <Grid item xs={6}>
              <IppAutocomplete
                id="CustomEmpCategoryID"
                toolTip={`${t(
                  "objBen:objects.employee.tooltips.customCategory"
                )}`}
                required={false}
                options={customEmpCategories}
                disabled={
                  ft_ben_DiversityDeclined && formik.values.DiversityDeclined
                }
                value={customEmpCategories.find((obj) => {
                  return (
                    obj.CustomEmpCategoryID ===
                    formik.values.CustomEmpCategoryID
                  );
                })}
                onChangeFunction={(
                  _: React.ChangeEvent<HTMLInputElement>,
                  newValue: any
                ) => {
                  if (newValue) {
                    formik.setFieldValue(
                      "CustomEmpCategoryID",
                      newValue?.CustomEmpCategoryID
                    );
                  } else {
                    formik.setFieldValue("CustomEmpCategoryID", null);
                  }
                }}
                label={t("objBen:objects.employee.fields.customCategory")}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                touchedFunction={formik.touched.CustomEmpCategoryID}
                errorFunction={formik.errors.CustomEmpCategoryID}
                optionLabelFunction={(option: any) => option.Name}
                textValueFunction={
                  !customEmpCategoryIsLoading &&
                  !pageLoading &&
                  formik.values.CustomEmpCategoryID > 0
                    ? customEmpCategoriesById[formik.values.CustomEmpCategoryID]
                        .Name
                    : ""
                }
                placeholder={
                  ft_ben_DiversityDeclined && formik.values.DiversityDeclined
                    ? "Declined"
                    : ""
                }
                autoPopulate={false}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <IppDiversityCheckboxes
              parentTitle="Employee"
              isEditing={isEditing}
              handleChange={formik.handleChange}
              disability={formik.values.Disability}
              visibleMinority={formik.values.VisibleMinority}
              lgbtq={formik.values.LGBTQ}
              local={formik.values.Local}
              checkboxesDisabled={
                ft_ben_DiversityDeclined && formik.values.DiversityDeclined
              }
            />
          </Grid>
          <Grid item xs={12}>
            <IppEditPencil isEditing={isEditing} setIsEditing={setIsEditing} />
          </Grid>

          <Grid item xs={12}>
            <IppFormButtons
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              isAdding={isAdding}
              showDelete={true}
              deleteFunction={handleDelete}
              resetFunction={() => formik.resetForm()}
              fetchFunc={fetchDeleteChecksByEmployee}
            />
          </Grid>

          <IppConfirmDialog
            title={t(
              "strGen:components.ippconfirmdialog.employeeform.indigenouswarning.title"
            )}
            open={warningDialogOpen}
            setOpen={setWarningDialogOpen}
            onConfirm={handleWarningDialogContinue}
            confirmText={t("strGen:buttons.continue")}
            content={t(
              "strGen:components.ippconfirmdialog.employeeform.indigenouswarning.content"
            )}
          />
        </Grid>
      </form>
    </Root>
  );

  let pageForm =
    pageLoading ||
    isLoading ||
    clientIsLoading ||
    genderIsLoading ||
    regionIsLoading ||
    companyIsLoading ||
    jobPositionIsLoading ||
    indigenousGroupIsLoading ||
    workHourIsLoading ||
    jobHistoryIsLoading ? (
      <LoadingIndicator />
    ) : isAdding ? (
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>{editForm}</Paper>
      </Box>
    ) : (
      <Grid container spacing={1}>
        <Grid item xs={customBP ? 12 : 5}>
          {isEditing ? (
            <Dialog open={isEditing} fullWidth maxWidth="lg">
              <DialogContent>{editForm}</DialogContent>
            </Dialog>
          ) : (
            <div>{viewForm}</div>
          )}
        </Grid>
        <Grid item xs={customBP ? 12 : 7}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={activeTab}
                      onChange={handleTabSelect}
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                    >
                      <Tab
                        value={0}
                        label={`Work Hour History (${workHourDetailsList.length})`}
                      />
                      {ft_ben_JobHistory && (
                        <Tab
                          value={1}
                          label={`Job History (${empJobHistory.length})`}
                        />
                      )}
                    </Tabs>
                  </Box>
                  <IppTabPanel value={activeTab} index={0}>
                    <Box>
                      <IppChildInventory
                        title="Work History"
                        parentTitle="Employee"
                        linkURL="employee"
                        tableData={data}
                        idField="WorkHourDetailID"
                        nameField="ProjectName"
                        parentID={employeeData.EmployeeID}
                        relatedField="EmployeeID"
                        columns={whdColumns}
                        showAdd={false}
                        showEdit={false}
                        tableName="Work History"
                        id="WorkHistoryList"
                        label="Work History"
                        options={workHourDetails}
                        selectedValues={workHourDetailsList}
                        setSelectedValues={null}
                        setDidSaveInventory={null}
                        parentValue={employeeData}
                        showExport={true}
                        noLinkCell={true}
                      />
                    </Box>
                  </IppTabPanel>
                  {ft_ben_JobHistory && (
                    <IppTabPanel value={activeTab} index={1}>
                      <Box>
                        <IppChildInventory
                          title="Job History"
                          parentTitle="Employee"
                          linkURL="employee"
                          tableData={empJobHistory}
                          idField="JobHistoryID"
                          nameField="JobPositionName"
                          parentID={employeeData.EmployeeID}
                          relatedField="JobHistoryID"
                          columns={jhColumns}
                          showAdd={true}
                          showEdit={true}
                          showOptions={true}
                          tableName="JobHistory"
                          id="PositionHistoryList"
                          label="Job History"
                          options={jobHistory}
                          selectedValues={jobHistoryList}
                          setSelectedValues={null}
                          setDidSaveInventory={null}
                          parentValue={employeeData}
                          noLinkCell={true}
                          deleteMessageType="NameValueFirst"
                          deleteNoteType="Delete"
                        />
                      </Box>
                    </IppTabPanel>
                  )}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

  return <Root>{pageForm}</Root>;
};
