import React from "react";
import { MenuItem, ListItemIcon, ListSubheader, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import {
  getContractorUrl,
  getAdminUrl,
  adminTrainingHubUrl,
  hasTrainingHub,
} from "utils/trainingUrlUtils";
import { getCurrentModule } from "utils/urlUtils";
import { School } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

interface TrainingHubSelectorProps {
  handleClose: () => void;
  iconList: (moduleID: number) => React.ReactNode;
  clientModules: Array<{ ShortName: string }>;
}

const TrainingHubSelector: React.FC<TrainingHubSelectorProps> = ({
  handleClose,
  iconList,
  clientModules,
}) => {
  const { t } = useTranslation(["strGen"]);

  const { customTrainingUrl } = useSelector((state: RootState) => state.client);
  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const currentModule = getCurrentModule();

  const isClient = currentProfile.IsClient;

  const renderTrainingHubItem = (
    module: string,
    getUrl: (module: string) => string,
    label: string,
    icon: React.ReactNode,
    handleClose: () => void
  ) => {
    const isAccessible = clientModules.some(
      (clientModule) => clientModule.ShortName === module
    );

    return (
      isAccessible && (
        <MenuItem
          component="a"
          href={getUrl(module)}
          target="_blank"
          onClick={handleClose}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          {label}
        </MenuItem>
      )
    );
  };

  if (isClient) {
    return (
      <>
        <ListSubheader>Admin Training Hub's</ListSubheader>

        <MenuItem
          component="a"
          href={adminTrainingHubUrl}
          target="_blank"
          onClick={handleClose}
        >
          <ListItemIcon>{iconList(7)}</ListItemIcon>
          {"Admin Training Hub"}
        </MenuItem>

        {renderTrainingHubItem(
          "engagement",
          getAdminUrl,
          "Engagements and Relations",
          iconList(3),
          handleClose
        )}
        {renderTrainingHubItem(
          "commitments",
          getAdminUrl,
          "Commitments and Agreements",
          iconList(2),
          handleClose
        )}
        {renderTrainingHubItem(
          "benefits",
          getAdminUrl,
          "Benefits",
          iconList(1),
          handleClose
        )}

        <Divider />

        <ListSubheader>Contractor Training Hub</ListSubheader>
        {renderTrainingHubItem(
          "benefits",
          (module) => getContractorUrl(customTrainingUrl, module),
          "Benefits",
          iconList(1),
          handleClose
        )}
      </>
    );
  }

  // For contractors, directly navigate based on the current module
  return (
    <>
      {currentModule && hasTrainingHub(currentModule) ? (
        <MenuItem
          component="a"
          href={getContractorUrl(customTrainingUrl)}
          target="_blank"
          onClick={handleClose}
        >
          <ListItemIcon>
            <School />
          </ListItemIcon>
          {t(`strGen:modules.${currentModule}`)}
        </MenuItem>
      ) : (
        <MenuItem disabled>{t("strGen:noTrainingHub")}</MenuItem>
      )}
    </>
  );
};

export default TrainingHubSelector;
