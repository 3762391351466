import { Grid } from "@mui/material";
import { useState } from "react";
import { useTypedTranslation } from "utils/customHooks";
import { IppCompleteButton } from "./IppCompleteButton";
import IppConfirmDialog from "components/IppConfirmDialog";

interface IppFormCompleteButtonProps {
  completeFunction: any;
  tooltip?: string;
}

export const IppFormCompleteButton = (props: IppFormCompleteButtonProps) => {
  const { completeFunction, tooltip } = props;

  const t = useTypedTranslation(["strGen"]);

  const [completeConfirmOpen, setCompleteConfirmOpen] = useState(false);

  const handleComplete = () => {
    completeFunction();
  };

  return (
    <div>
      <Grid container justifyContent="flex-end">
        <Grid item xs={4}>
          <Grid container justifyContent="flex-end">
            <IppCompleteButton
              onClick={() => setCompleteConfirmOpen(true)}
              tooltip={tooltip}
            />
          </Grid>
        </Grid>
      </Grid>
      <IppConfirmDialog
        title={t("strGen:prompts.completeaction")}
        open={completeConfirmOpen}
        setOpen={setCompleteConfirmOpen}
        onConfirm={handleComplete}
        content={t("strGen:prompts.confirmcompleteaction")}
        confirmText="Complete"
      />
    </div>
  );
};
