import { Chip } from "@mui/material";

export const getStatusChips = (field: string, type: string) => {
  switch (field) {
    case "CommitmentStatusTypeName":
      return getCommitmentStatusChips(type);
    case "WorkflowStageName":
      return getPaymentStageChips(type);
    case "StatusTypeName":
      return getCommunicationStatusChips(type);
    case "ActionStatusName":
      return getActionStatusChips(type);
    case "StatusName":
      return getSubmitStatusChips(type);
    default:
      return null;
  }
};

function getCommitmentStatusChips(type: string) {
  switch (type) {
    case "Action Not Required":
      return <Chip label={type} size="small" color="info" />;
    case "Action Not Yet Required":
      return <Chip label={type} size="small" color="info" />;
    case "Not Started":
      return <Chip label={type} size="small" color="error" />;
    case "In Progress":
      return <Chip label={type} size="small" color="info" />;
    case "Ongoing - Commitment Being Met":
      return <Chip label={type} size="small" color="info" />;
    case "Ongoing - Commitment Not Being Met":
      return <Chip label={type} size="small" color="warning" />;
    case "Completed":
      return <Chip label={type} size="small" color="success" />;
    case "Complete - Commitment Met":
      return <Chip label={type} size="small" color="success" />;
    case "Complete - Commitment Not Met":
      return <Chip label={type} size="small" color="success" />;
    case "Complete - Not Required":
      return <Chip label={type} size="small" color="success" />;
    default:
      return <Chip label={type} size="small" />;
  }
}

function getPaymentStageChips(type: string) {
  switch (type) {
    case "In Progress":
      return <Chip label={type} size="small" color="info" />;
    case "Submitted for Review":
      return <Chip label={type} size="small" color="warning" />;
    case "Complete":
      return <Chip label={type} size="small" color="success" />;
    case "Referred Back":
      return <Chip label={type} size="small" color="error" />;
    default:
      return <Chip label={type} size="small" />;
  }
}

function getCommunicationStatusChips(type: string) {
  switch (type) {
    case "Scheduled":
      return <Chip label={type} size="small" color="info" />;
    case "In Progress":
      return <Chip label={type} size="small" color="info" />;
    case "Completed":
      return <Chip label={type} size="small" color="success" />;
    case "Overdue":
      return <Chip label={type} size="small" color="error" />;
    case "Cancelled":
      return <Chip label={type} size="small" color="error" />;
    default:
      return <Chip label={type} size="small" />;
  }
}

export const getActionStatusChips = (type: string) => {
  switch (type) {
    case "Not Started":
      return <Chip label={type} size="small" color="error" />;
    case "In Progress":
      return <Chip label={type} size="small" color="info" />;
    case "Closed Completed":
      return <Chip label={type} size="small" color="success" />;
    case "Closed Incomplete":
      return <Chip label={type} size="small" color="warning" />;
    case "No Longer Needed":
      return <Chip label={type} size="small" color="info" />;
    default:
      return <Chip label={type} size="small" />;
  }
};

function getSubmitStatusChips(type: string) {
  switch (type) {
    case "On Hold":
      return <Chip label={type} size="small" color="info" />;
    case "Not Started":
      return <Chip label={type} size="small" color="warning" />;
    case "Ongoing":
      return <Chip label={type} size="small" color="warning" />;
    case "Completed":
      return <Chip label={type} size="small" color="success" />;
    case "Draft":
      return <Chip label={type} size="small" color="warning" />;
    case "Submitted":
      return <Chip label={type} size="small" color="success" />;
    default:
      return <Chip label={type} size="small" />;
  }
}
